"use client";

import * as React from "react";

import { Box } from "@mpay/web-tickets/src/components/common/Box";
import { Button } from "@mpay/web-tickets/src/components/common/Button/Button";
import { getUserOrLoading } from "@mpay/web-tickets/src/utilities/user/userContext";

export function ClientSection() {
  const [user] = getUserOrLoading();

  const displayClientDashboardLink = user?.type === "client";

  if (displayClientDashboardLink) {
    return (
      <>
        <section>
          <Box>
            <div>
              <div className="tw-text-3xl">Welcome back.</div>
              <div className="tw-text-sm">
                Logged in as{" "}
                <span className="tw-font-semibold">{user.username}</span>.
              </div>
            </div>
            <div className="tw-mt-12">
              <Button href="/host/overview" color="green" visualSize="large">
                Go to your dashboard.
              </Button>
            </div>
          </Box>
        </section>
      </>
    );
  }

  return null;
}
